export default {
	items: [
		{
			name: 'Dashboard',
			url: '/dashboard',
			icon: 'icon-speedometer',
			badge: {
				variant: 'primary',
			},
		},
		{
			name: 'Account Setup',
			url: '/setup',
			icon: 'icon-settings',
			children: [
				{
					name: 'Company',
					url: '/company',
					icon: 'fa fa-building-o',
				},
				{
					name: 'Storage Location',
					url: '/location',
					icon: 'icon-location-pin',
				},
				{
					name: 'User',
					url: '/user',
					icon: 'icon-user',
				},
				{
					name: 'Connection',
					url: '/connection',
					icon: 'fa fa-connectdevelop',
				},
				{
					name: 'Transportation',
					url: '/transportation',
					icon: 'fa fa-truck',
				},
			],
		},
		{
			name: 'Asset Management',
			url: '/asset',
			icon: 'fa fa-tasks',
			children: [
				{
					name: 'Asset Type',
					url: '/asset-type',
					icon: 'fa fa-cube',
				},
				{
					name: 'Asset Pool Distribution',
					url: '/asset-pool-distribution',
					icon: 'fa fa-cubes',
				},
			],
		},
		{
			name: 'Transactions',
			url: '/transaction',
			icon: 'icon-book-open',
			children: [
				{
					name: 'Dispatch',
					url: '/dispatch',
					icon: 'fa fa-upload',
				},
				{
					name: 'Receipt',
					url: '/receipt',
					icon: 'fa fa-download',
				},
				{
					name: 'Maintenance',
					url: '/maintenance',
					icon: 'icon-wrench',
				},
			],
		},
		{
			name: 'Reports',
			url: '/reports',
			icon: 'fa fa-line-chart',
			children: [
				{
					name: 'Inventory Summary',
					url: '/inventory-summary',
					icon: 'fa fa-bar-chart',
				},
				{
					name: 'Assets with Truckers',
					url: '/assets-with-truckers',
					icon: 'fa fa-truck',
				},
				{
					name: 'Incident Report',
					url: '/incident-reports',
					icon: 'fa fa-warning'
				},
				{
					name: 'Dispatch Alert Logs',
					url: '/dispatch-alerts',
					icon: 'fa fa-bell'
				},
				{
					name: 'Asset Accountability',
					url: '/asset-accountability',
					icon: 'fa fa-pie-chart',
				}
			],
		},
		{
			name: 'Support',
			url: '/support',
			icon: 'fa fa-handshake-o',
			children: [
				{
					name: 'Transfer Company',
					url: '/transfer-company-requests',
					icon: 'fa fa-exchange',
				},
			],
		},
		{
			name: 'Data Importer',
			url: '/data-importer',
			icon: 'icon-docs',
			children: [
				{
					name: 'Company Importer',
					url: '/company-importer',
					icon: 'icon-docs',
				},
				{
					name: 'Location Importer',
					url: '/storage-location-importer',
					icon: 'icon-docs',
				},
				{
					name: 'User Importer',
					url: '/user-importer',
					icon: 'icon-docs',
				},
				{
					name: 'Transportation Importer',
					url: '/transportation-importer',
					icon: 'icon-docs',
				},
			],
		},
	],
};
